<template>
  <div>
    <a-row>
      <a-col :span="22">
        <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">
          <a-form-model-item>
            <a-input v-model="form.name" placeholder="姓名" style="width: 150px"></a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-input v-model="form.deptName" placeholder="部门" style="width: 150px"></a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-range-picker
              :allowClear="false"
              :placeholder="['开始时间', '结束时间']"
              v-model="form.date"
              style="width: 280px"
            />
          </a-form-model-item>

          <a-form-model-item>
            <a-space>
              <a-button @click="query" type="primary">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-form-model>
      </a-col>
      <a-col :span="2">
        <div class="right" style="margin-top: 4px">
          <a-button @click="download" type="primary">导出</a-button>
        </div>
      </a-col>
    </a-row>

    <Padding />

    <a-table
      bordered
      :data-source="list"
      :loading="loading"
      @change="onChange"
      :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
          showSizeChanger: true,
        }"
      rowKey="id"
    >
      <a-table-column title="部门" data-index="deptName"></a-table-column>
      <a-table-column title="姓名" data-index="name" align="center"></a-table-column>

      <a-table-column title="类型" align="center">
        <template slot-scope="text">
          <a-tag color="green" v-if="text.checkType === 'OffDuty'">出门</a-tag>
          <a-tag color="blue" v-else-if="text.checkType === 'OnDuty'">进门</a-tag>
          <a-tag color="red" v-else>未知</a-tag>
        </template>
      </a-table-column>

      <a-table-column title="设备" data-index="deviceAlias" align="center"></a-table-column>
      <a-table-column title="出入时间" data-index="userCheckTime" align="center"></a-table-column>
    </a-table>
  </div>
</template>


<script>
import { fetchAccessList } from "@/api/human-resources/clock";
import moment from "moment";
import { downloadByHtml } from "@/utils/xlsx";

export default {
  data() {
    return {
      form: {
        date: [moment(), moment()]
      },

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;

      let startTime, endTime;
      if (Array.isArray(form.date) && form.date.length === 2) {
        startTime = form.date[0].format("YYYY-MM-DD" + " 00:00:00");
        endTime = form.date[1].format("YYYY-MM-DD" + " 23:59:59");
      }

      fetchAccessList({
        pageNum: current,
        pageSize: pageSize,
        ...form,
        date: undefined,
        startTime,
        endTime
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {
        date: [moment(), moment()]
      };
      this.current = 1;
      this.getList();
    },
    download() {
      downloadByHtml(document.getElementsByTagName("table")[0], "出入统计");
    }
  }
};
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',[_c('a-col',{attrs:{"span":22}},[_c('a-form-model',{attrs:{"colon":false,"model":_vm.form,"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"姓名"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"部门"},model:{value:(_vm.form.deptName),callback:function ($$v) {_vm.$set(_vm.form, "deptName", $$v)},expression:"form.deptName"}})],1),_c('a-form-model-item',[_c('a-range-picker',{staticStyle:{"width":"280px"},attrs:{"allowClear":false,"placeholder":['开始时间', '结束时间']},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}})],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1)],1),_c('a-col',{attrs:{"span":2}},[_c('div',{staticClass:"right",staticStyle:{"margin-top":"4px"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.download}},[_vm._v("导出")])],1)])],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
        showSizeChanger: true,
      },"rowKey":"id"},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"部门","data-index":"deptName"}}),_c('a-table-column',{attrs:{"title":"姓名","data-index":"name","align":"center"}}),_c('a-table-column',{attrs:{"title":"类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [(text.checkType === 'OffDuty')?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("出门")]):(text.checkType === 'OnDuty')?_c('a-tag',{attrs:{"color":"blue"}},[_vm._v("进门")]):_c('a-tag',{attrs:{"color":"red"}},[_vm._v("未知")])]}}])}),_c('a-table-column',{attrs:{"title":"设备","data-index":"deviceAlias","align":"center"}}),_c('a-table-column',{attrs:{"title":"出入时间","data-index":"userCheckTime","align":"center"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <Pane />

    <a-card class="container">
      <PassTable />
    </a-card>
  </div>
</template>
    
  <script>
import PassTable from "./components/pass-table.vue";
export default {
  components: {
    PassTable
  }
};
</script>